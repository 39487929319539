<template>
    <div
      :class="toggleVisibility"
      class="absolute w-full bottom-14 left-1/2 transform -translate-x-1/2 translate-y-0 transition-all duration-300 flex items-center justify-center"
    >
      <div class="bg-secondary-900 flex items-center rounded-lg pr-4 start-feature-modal relative">
        <div class="flex-shrink-0">
          <slot name="image" />
        </div>
        <div class="mr-8 p-4 text-secondary-50">
          <slot name="body" />
        </div>
        <div class="flex items-center space-x-2">
          <slot name="actions" />
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'banner-start-feature-layout',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      visibility: false
    }
  },
  computed: {
    toggleVisibility: function () {
      return this.isOpen ? 'translate-y-0 z-20' : 'translate-y-full invisible opacity-0 z-10'
    }
  }
}
</script>

<style lang="scss" scoped>
.start-feature-modal {
  &:before {
    content:'';
    position: absolute;
    top:-17%;
    left: 1%;
    width: 98%;
    height: 15px;
    overflow: hidden;
    background-image: url('~@/assets/img/top-modal-init.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
